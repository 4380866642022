import React, { useState } from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import "../components/column-layout.css"
import "./index.css"
import Hello from "../components/hello"
import TwitterIcon from "../components/twitter-icon"

const IndexPage = () => {
  const [isAnimationComplete, setIsAnimationComplete] = useState(false)
  return (
    <Layout>
      <SEO title="Home" />

      <div className="column-layout">
        <div className="column-layout__main">
          <div className="column-layout__centered-content">
            <Hello onAnimationFinished={() => setIsAnimationComplete(true)} />
          </div>
        </div>
        <div className="column-layout__footer">
          <a
            href="https://twitter.com/peterssonjesper"
            target="_blank"
            rel="noopener noreferrer"
            aria-label="Follow me on Twitter"
            className={`twitter-icon ${
              isAnimationComplete ? "twitter-icon--visible" : ""
            }`}
          >
            <TwitterIcon />
          </a>
          <div className="lines">
            <hr className="line line--color-1" />
            <hr className="line line--color-2" />
            <hr className="line line--color-3" />
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default IndexPage
