import React, { useEffect } from "react"
import anime from "animejs/lib/anime.es.js"
import "./hello.css"

const delay = i => {
  if (i < 6) {
    return i * 100
  }
  return i * 80
}

export default function Hello({ onAnimationFinished = () => {} }) {
  useEffect(() => {
    anime({
      targets: ".hello__illustration path",
      fill: ["#282a36", "#fff"],
      duration: 1000,
      easing: "easeInOutSine",
      delay: 2000,
      direction: "forward",
      complete: onAnimationFinished,
    })
    anime({
      targets: ".hello__illustration path",
      strokeDashoffset: [anime.setDashoffset, 0],
      stroke: "#fff",
      easing: "easeInOutSine",
      delay: (el, i) => delay(i),
      direction: "forward",
    })
  }, [])

  return (
    <h1 className="hello">
      <span className="hello__hidden-title">Hello, I'm Jesper!</span>
      <svg
        className="hello__illustration"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 1023.4 148.68"
      >
        <g
          fill="none"
          fillRule="evenodd"
          stroke="none"
          strokeWidth="1"
          transform="translate(-449.36 -590.716)"
        >
          <path
            d="M95.37-78.2C100.3-86.7,102-93.16,102-97.92a15.076,15.076,0,0,0-1.53-6.46c-1.7-3.74-4.25-4.25-7.31-.34-6.8,9.01-21.42,35.02-32.3,56.61-9.35.85-18.02,3.06-28.73,4.59L50.32-76.67c4.08-7.48,5.61-14.11,5.61-18.87a14.108,14.108,0,0,0-1.53-6.97c-1.87-3.23-4.42-3.74-7.14-.17-7.14,10.2-22.61,38.42-33.15,60.35-1.87,0-2.89-.34-5.44-.51-4.59-.34-5.61.85-3.91,4.08a13.05,13.05,0,0,0,5.44,5.1C3.06-16.83,1.36-11.73,1.36-6.8A14.4,14.4,0,0,0,4.25,2.04c2.04,2.89,5.1,2.04,6.29-.85C15.47-9.69,20.4-20.57,25.33-30.77c7.65,0,18.53-2.04,29.07-3.4-2.72,5.61-4.93,11.22-6.12,14.45-1.53,4.59-2.21,7.99-2.21,10.54A15.088,15.088,0,0,0,48.96-.51c1.87,3.06,4.76,2.21,5.95-.51C60.52-13.43,66.13-24.99,71.57-35.7a56.657,56.657,0,0,1,7.82.51c3.23.51,4.42-.85,3.91-3.06a11.656,11.656,0,0,0-6.12-7.48C80.92-52.53,84.66-59.16,95.37-78.2Z"
            transform="translate(448 701)"
          />
          <path
            d="M42.33-57.97c1.87,1.53-1.7,9.52-6.63,16.15-3.4,4.59-8.84,9.69-12.92,9.69a6.87,6.87,0,0,1-3.74-1.19C27.2-49.64,39.78-60.01,42.33-57.97Zm12.75,6.8a12.372,12.372,0,0,0-1.7-6.29C51-60.86,47.6-63.58,42.84-66.3a11.022,11.022,0,0,0-5.95-1.36c-9.86,0-23.63,9.01-32.47,27.03A42.265,42.265,0,0,0-.85-21.08c0,13.6,8.67,23.97,24.48,23.8,13.26-.17,26.86-8.16,34-18.53,3.06-4.59,2.89-9.52,2.38-11.05-1.36-3.4-3.57-3.06-5.44-.34C47.6-16.66,36.21-4.08,23.29-4.08c-5.1,0-8.16-5.27-8.16-12.75a35.986,35.986,0,0,1,1.53-10.03,21.85,21.85,0,0,0,12.07,3.74C43.69-23.12,55.08-40.97,55.08-51.17Z"
            transform="translate(535 701)"
          />
          <path
            d="M57.97-91.12a16.582,16.582,0,0,0-5.61-12.41c-3.23-3.06-6.29-2.04-9.52,1.19C26.18-84.83,6.97-53.38.34-25.16A40.712,40.712,0,0,0-.85-15.47C-.85-3.74,5.61,2.21,15.98,2.21c14.28,0,26.86-13.94,29.41-19.04,2.21-4.59,2.38-8.5,1.7-11.05-1.19-4.42-4.93-4.42-7.14-.17C33.49-15.81,23.63-4.42,15.81-4.42c-2.21,0-3.23-2.89-3.23-6.97,0-5.95,2.21-13.6,5.61-22.27C33.15-43.69,51.68-63.75,56.95-83.98A22.589,22.589,0,0,0,57.97-91.12Zm-9.52,4.25c1.02.68-1.19,8.16-6.97,17.85a106.794,106.794,0,0,1-17,21.59c1.19-2.89,8.67-15.64,13.09-23.12C43.35-80.58,47.77-87.21,48.45-86.87Z"
            transform="translate(594 701)"
          />
          <path
            d="M57.97-91.12a16.582,16.582,0,0,0-5.61-12.41c-3.23-3.06-6.29-2.04-9.52,1.19C26.18-84.83,6.97-53.38.34-25.16A40.712,40.712,0,0,0-.85-15.47C-.85-3.74,5.61,2.21,15.98,2.21c14.28,0,26.86-13.94,29.41-19.04,2.21-4.59,2.38-8.5,1.7-11.05-1.19-4.42-4.93-4.42-7.14-.17C33.49-15.81,23.63-4.42,15.81-4.42c-2.21,0-3.23-2.89-3.23-6.97,0-5.95,2.21-13.6,5.61-22.27C33.15-43.69,51.68-63.75,56.95-83.98A22.589,22.589,0,0,0,57.97-91.12Zm-9.52,4.25c1.02.68-1.19,8.16-6.97,17.85a106.794,106.794,0,0,1-17,21.59c1.19-2.89,8.67-15.64,13.09-23.12C43.35-80.58,47.77-87.21,48.45-86.87Z"
            transform="translate(637 701)"
          />
          <path
            d="M58.65-36.04c1.36-1.7,1.02-3.23-3.4-3.23,1.53-14.45-3.23-15.64-7.99-21.25-3.23-4.42-6.29-6.29-11.22-6.29-11.22,0-21.93,8.5-29.58,22.1C2.04-36.72-.68-28.39-.68-20.91A24.04,24.04,0,0,0,4.08-6.12,20.678,20.678,0,0,0,20.74,2.55a19.021,19.021,0,0,0,6.12-1.02c12.92-4.42,23.8-21.08,27.37-35.19A7.11,7.11,0,0,0,58.65-36.04ZM42.5-42.67c-1.87-2.21-2.72-6.63-2.72-9.69,0-2.72,1.53-4.08,2.55-4.08,1.19,0,2.55,1.02,2.55,3.57A28.444,28.444,0,0,1,42.5-42.67ZM12.58-13.26c0-6.29,2.72-15.81,7.14-23.97a54.638,54.638,0,0,1,9.69-12.75c1.36,5.95,4.93,10.37,10.37,13.77a58.127,58.127,0,0,1-5.61,12.24C27.88-12.41,21.08-6.12,16.66-6.12,14.11-6.12,12.58-8.16,12.58-13.26Z"
            transform="translate(682 701)"
          />
          <path
            d="M7.82,8.67a9.94,9.94,0,0,0,3.23.68c6.29.17,13.6-5.44,15.64-11.73,2.21-6.63-1.19-12.41-6.12-12.58A11.227,11.227,0,0,0,10.03-6.29,8.224,8.224,0,0,0,12.58,1.7c-1.19.85-3.57,2.38-4.76,3.06C6.29,5.61,5.27,7.65,7.82,8.67Z"
            transform="translate(731 701)"
          />
          <path
            d="M-1.87-18.87A37.464,37.464,0,0,0-3.91-8.33c0,4.25,1.36,7.31,3.06,9.01C1.87,3.74,3.57,3.23,4.93,0,16.66-26.18,35.53-59.33,45.9-77.69c4.42-7.99,6.46-15.13,6.46-19.72a15.46,15.46,0,0,0-1.53-6.8c-1.87-3.57-4.42-4.08-7.14-.17C30.26-85.17,6.97-41.99-1.87-18.87Z"
            transform="translate(819 701)"
          />
          <path
            d="M46.58-96.56c2.04-3.23,3.57-7.48,2.38-10.88-1.19-3.06-3.74-4.25-7.14-.51-6.12,6.63-15.81,24.14-19.55,32.47-1.02,2.21.17,3.91,1.87,4.25,1.87.34,3.91,0,6.12-2.72A273.186,273.186,0,0,0,46.58-96.56Z"
            transform="translate(854 701)"
          />
          <path
            d="M28.39-66.47c-1.7-3.06-4.25-3.23-6.46,0C11.56-50.66-.68-27.03-4.25-16.83A28.109,28.109,0,0,0-6.12-7.65,11.615,11.615,0,0,0-2.04,1.36C.85,3.91,2.72,2.89,3.91.17c6.63-15.64,14.45-31.11,23.97-41.31C38.08-52.36,44.71-56.1,46.07-56.1c1.53,0-3.23,6.46-7.82,14.11-4.59,7.14-10.2,16.32-12.58,22.44-1.7,4.25-3.06,9.69-3.06,12.58,0,4.42,1.19,6.8,3.74,9.35,2.72,2.72,5.1,2.21,6.8-1.02,7.14-15.81,15.3-31.96,28.56-45.22,10.03-10.03,13.6-11.73,14.96-11.73s-2.72,5.1-6.46,10.71c-3.91,6.12-9.52,15.3-12.24,21.08a27.573,27.573,0,0,0-2.55,11.05c0,9.18,6.29,15.47,17.85,15.47,10.54,0,22.61-8.5,27.37-18.36,2.04-3.91,1.87-10.37,0-12.24-1.53-1.53-3.23-1.36-4.76,1.19C89.59-16.32,81.26-4.42,75.99-4.42c-2.72,0-4.42-2.72-4.42-6.63,0-5.95,4.42-13.43,9.18-20.74C87.72-42.5,91.29-50.32,91.29-56.1c0-5.61-2.72-10.2-7.65-10.37-5.44-.17-14.45,5.61-22.1,12.41,0-7.14-3.57-13.26-9.01-13.26s-14.96,6.46-22.27,13.6a23.637,23.637,0,0,0,.51-4.59A16.158,16.158,0,0,0,28.39-66.47Z"
            transform="translate(879 701)"
          />
          <path
            d="M-1.53-26.52a29.346,29.346,0,0,0-.85,7.65C-2.38-5.78,6.46,3.4,22.78,3.4c12.07,0,24.31-6.12,35.19-24.31C67.15-36.04,83.3-69.02,89.08-82.62a35.411,35.411,0,0,0,3.23-13.26,15.79,15.79,0,0,0-2.04-8.33c-2.04-3.57-4.59-3.91-7.14-.17-5.95,8.84-20.06,37.23-28.9,55.25C43.86-27.54,31.62-4.59,19.21-4.59c-4.08,0-7.31-2.21-7.31-12.24,0-4.76,1.36-11.39,3.74-18.7,1.19-3.91-.85-5.61-4.59-4.76C6.12-39.1.51-34.17-1.53-26.52Z"
            transform="translate(1022 701)"
          />
          <path
            d="M42.33-57.97c1.87,1.53-1.7,9.52-6.63,16.15-3.4,4.59-8.84,9.69-12.92,9.69a6.87,6.87,0,0,1-3.74-1.19C27.2-49.64,39.78-60.01,42.33-57.97Zm12.75,6.8a12.372,12.372,0,0,0-1.7-6.29C51-60.86,47.6-63.58,42.84-66.3a11.022,11.022,0,0,0-5.95-1.36c-9.86,0-23.63,9.01-32.47,27.03A42.265,42.265,0,0,0-.85-21.08c0,13.6,8.67,23.97,24.48,23.8,13.26-.17,26.86-8.16,34-18.53,3.06-4.59,2.89-9.52,2.38-11.05-1.36-3.4-3.57-3.06-5.44-.34C47.6-16.66,36.21-4.08,23.29-4.08c-5.1,0-8.16-5.27-8.16-12.75a35.986,35.986,0,0,1,1.53-10.03,21.85,21.85,0,0,0,12.07,3.74C43.69-23.12,55.08-40.97,55.08-51.17Z"
            transform="translate(1101 701)"
          />
          <path
            d="M19.55-5.27c-3.74,0-7.31-5.95-9.35-13.94,5.1-7.31,9.86-14.62,13.94-21.08.34,4.59.51,10.03.51,15.47C24.65-13.77,23.12-5.27,19.55-5.27ZM-3.74-21.59A22.962,22.962,0,0,0-.85-10.37C4.93.34,15.13,3.06,25.33,2.72c12.75-.51,24.65-6.97,31.11-18.87,3.06-5.27,2.55-10.03,1.7-11.73-1.19-2.72-3.74-3.91-5.27,0A36.862,36.862,0,0,1,38.08-9.18a32.505,32.505,0,0,0,2.89-12.75c.34-9.18-1.87-20.23-1.87-32.3,0-2.21.17-5.1.34-7.14,5.27-8.67,4.25-12.41-.34-12.41a13.209,13.209,0,0,0-8.33,3.57c-3.91,3.23-5.95,7.14-6.63,13.26C19.72-49.64,13.77-40.8,8.16-32.81c-.51-4.08-3.06-4.93-6.46-2.04C-2.04-31.62-3.74-26.69-3.74-21.59Z"
            transform="translate(1159 701)"
          />
          <path
            d="M25.33-39.95c7.31-12.41,16.66-20.23,19.72-18.87.85.34,1.19,2.38,1.19,5.44,0,5.61-2.04,14.28-6.12,22.27C33.15-17,25.5-7.65,20.23-7.65c-4.42,0-5.61-12.24-.34-23.12Zm35.36-8.33c0-8.67-4.93-17.85-15.13-17.85-4.59,0-9.52,2.21-13.77,5.1a10.359,10.359,0,0,0-1.7-6.29c-1.36-1.87-3.57-2.55-6.63,1.19C12.92-52.02-15.13.85-20.06,17A36.468,36.468,0,0,0-22.1,28.05c0,3.57.85,5.95,3.4,9.18,1.7,2.04,3.74,1.36,5.1-1.7C-6.46,18.87.85,4.25,8.33-9.69,12.24-.51,20.57,4.08,34,4.08c14.11,0,28.39-4.76,36.04-18.53a18.065,18.065,0,0,0,2.38-11.56c-.68-3.4-4.25-4.25-6.29-.34C61.2-16.49,51.51-5.27,38.93-2.72c5.44-4.42,11.05-12.07,15.13-21.42C57.97-32.3,60.69-40.97,60.69-48.28Z"
            transform="translate(1216 701)"
          />
          <path
            d="M42.33-57.97c1.87,1.53-1.7,9.52-6.63,16.15-3.4,4.59-8.84,9.69-12.92,9.69a6.87,6.87,0,0,1-3.74-1.19C27.2-49.64,39.78-60.01,42.33-57.97Zm12.75,6.8a12.372,12.372,0,0,0-1.7-6.29C51-60.86,47.6-63.58,42.84-66.3a11.022,11.022,0,0,0-5.95-1.36c-9.86,0-23.63,9.01-32.47,27.03A42.265,42.265,0,0,0-.85-21.08c0,13.6,8.67,23.97,24.48,23.8,13.26-.17,26.86-8.16,34-18.53,3.06-4.59,2.89-9.52,2.38-11.05-1.36-3.4-3.57-3.06-5.44-.34C47.6-16.66,36.21-4.08,23.29-4.08c-5.1,0-8.16-5.27-8.16-12.75a35.986,35.986,0,0,1,1.53-10.03,21.85,21.85,0,0,0,12.07,3.74C43.69-23.12,55.08-40.97,55.08-51.17Z"
            transform="translate(1287 701)"
          />
          <path
            d="M28.05-74.29c-5.27,0-16.15,12.07-16.15,20.74a8.794,8.794,0,0,0,3.23,6.29A234.448,234.448,0,0,0-5.78-16.32Q-9.35-9.69-9.35-4.08A13.5,13.5,0,0,0-5.1,5.95C-2.04,9.18.17,7.65.85,4.59,3.23-7.14,12.92-22.78,25.67-44.88A35.668,35.668,0,0,0,38.08-47.6c-8.5,10.37-17.51,23.46-17.51,34.34,0,9.18,5.61,16.49,15.98,16.49,9.69,0,19.72-7.31,23.8-17,1.87-4.59,1.7-8.67.51-11.05-1.53-3.06-3.91-2.89-5.27.17C50.32-12.92,43.52-3.4,37.74-3.4,35.36-3.4,34-6.12,34-10.54c0-10.03,9.69-23.46,18.87-34a10.511,10.511,0,0,0,2.72-6.63c0-4.08-2.55-8.5-4.93-10.88-2.21-2.21-3.91-1.87-6.8.17q-7.65,5.61-13.77,7.14c1.87-5.44,3.06-10.37,3.06-13.6C33.15-72.25,31.62-74.29,28.05-74.29Z"
            transform="translate(1344 701)"
          />
          <path
            d="M22.27-34.68a31.565,31.565,0,0,0-2.38,10.54,11.642,11.642,0,0,0,3.23,8.67c2.89,2.89,4.25,2.21,5.78-.85C37.4-34.85,51.51-61.2,60.69-77.69c4.59-8.33,6.29-14.96,6.29-19.72a15.887,15.887,0,0,0-1.7-6.63c-1.87-4.08-4.59-3.74-6.97-.17C45.56-85.68,28.9-52.7,22.27-34.68ZM8.33-6.97A13.686,13.686,0,0,0,10.37-.85c3.06,4.76,9.52,5.78,12.24,2.55a12.9,12.9,0,0,0,1.36-11.9c-1.87-3.74-5.27-5.95-8.33-5.95C11.56-16.15,8.33-11.39,8.33-6.97Z"
            transform="translate(1405 701)"
          />
        </g>
      </svg>
    </h1>
  )
}
